import React from 'react';
import {Plan} from '../../database/schemas/plan';
import {Card} from '../../styled-components/Card';
import {Row} from '../../styled-components/Row';
import {Field, Label, Value} from '../../styled-components/Field';
import ImageIcon from '../general/ImageIcon';
import {plansDir} from '../../hooks/usePlans';
import styled from 'styled-components/native';
import {contentUrl} from '../../api';
import { useContentSignature } from '../ContentSignature';

const PlanInfo = styled(Field)`
  max-width: 85%;
`;

interface PlanListItemProps {
  plan: Plan;
}

const PlanListItem = ({plan}: PlanListItemProps) => {
  const { contentSignature } = useContentSignature();
  return (
    <Card>
      <Row>
        <PlanInfo>
          <Label>
            {plan.contacts.length ? plan.contacts.length : 'No'} Contacts
          </Label>
          <Value>{plan.name}</Value>
        </PlanInfo>
        <ImageIcon
          source={{
            uri: `${contentUrl}/${plan.planID}/icon.png${contentSignature}`,
          }}
        />
      </Row>
    </Card>
  );
};

export default PlanListItem;
