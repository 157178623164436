import styled from 'styled-components/native';
import {View, Text} from 'react-native';

export const Card = styled(View)`
  width: 100%;
  margin: 5px auto;
  padding: 20px;
  background: white;
  border-radius: 5px;
`;

export const Title = styled(Text)`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
`;
