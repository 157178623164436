import styled from 'styled-components/native';
import {Platform, Text, View} from 'react-native';
import {windowIsWebDesktop, maxWidth} from './windowIsWeb';

const headerContainerPadding = `
  padding-left: calc((100vw - ${maxWidth.container}) / 2);
  padding-right: calc((100vw - ${maxWidth.container}) / 2);
`;

export const HeaderWrapper = styled(View)`
  flex-direction: row;
  justify-content: space-between;
  padding: 0 20px 10px;
  padding-top: ${Platform.OS === 'ios' ? '20px' : '0'};
  ${windowIsWebDesktop && `
    padding-top: 26px;
    ${headerContainerPadding}
  `};
`;

type HeaderProps = {
  background?: string;
};

export const Header = styled(View)<HeaderProps>`
  padding: 10px 20px 20px;
  background: ${(props) =>
    props.background ? props.background : props.theme.primary};
  ${windowIsWebDesktop && headerContainerPadding}
`;

type TitleProps = {
  color?: string;
};

export const Title = styled(Text)<TitleProps>`
  color: ${(props) => (props.color ? props.color : 'white')};
  font-size: 24px;
  text-align: left;
  font-weight: bold;
  margin-bottom: 5px;
`;

export const Description = styled(Text)`
  color: white;
`;
