import styled from 'styled-components/native';
import {Text} from 'react-native';

export const Light = styled(Text)`
  color: #999;
`;

export const Title = styled(Text)`
  font-weight: bold;
  font-size: 16px;
  margin: 5px 0 15px;
`;
