import React from 'react';

// Empty component which is never instantiated
// but used as a placeholder for navigation screens.

export const Redirect = () => {
  return <></>;
};

export default Redirect;
