/// <reference lib="dom" />
import DOA, {appName} from './doa.web';

const startDatabase = async () => {
  // const indexedDB: IDBFactory = window.indexedDB;
  // // const IDBTransaction = window.IDBTransaction;
  // let nextSchemaIndex = 2;
  //
  // const db: IDBDatabase = await new Promise((resolve, reject) => {
  //   console.log('IndexedDB schema version', nextSchemaIndex);
  //   const request = indexedDB.open(appName, nextSchemaIndex);
  //
  //   request.onupgradeneeded = function (event) {
  //     console.log('r', request);
  //     // Initialize object stores
  //     [
  //       'Plan',
  //       'Contents',
  //       'Contents',
  //       'ListContent',
  //       'ExpandableContent',
  //       'Contact',
  //     ].forEach((store: string) => {
  //       if (!event.target!.result.objectStoreNames.contains(store)) {
  //         event.target!.result.createObjectStore(store);
  //       }
  //     });
  //
  //     event.target!.result.close();
  //     const migrated = indexedDB.open(appName, nextSchemaIndex);
  //     migrated.onsuccess = function () {
  //       console.log('Success migrating IndexedDB database');
  //       resolve(migrated.result);
  //     };
  //     resolve(event.target!.result);
  //   };
  //
  //   request.onerror = function (event) {
  //     console.log('Error creating/accessing IndexedDB database', event);
  //     reject(event);
  //   };
  //
  //   request.onsuccess = function () {
  //     console.log('Success creating/accessing IndexedDB database');
  //     resolve(request.result);
  //
  //     request.result.onerror = function () {
  //       console.log('Error creating/accessing IndexedDB database');
  //     };
  //   };
  // });

  return new DOA();
};

export default startDatabase;
