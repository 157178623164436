import React, {PropsWithChildren} from 'react';
import {Text, View} from 'react-native';
import styled from 'styled-components/native';
import Centered from '../../styled-components/Centered';
import Icon from 'react-native-vector-icons/FontAwesome';

const Indicator = styled(View)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
  border-width: 10px;
  border-color: rgb(244, 219, 228);
  border-radius: 200px;
  background: rgb(243, 170, 174);
  margin-bottom: 20px;
`;

const Message = styled(Text)`
  max-width: 240px;
  margin: auto;
  text-align: center;
`;

const Error = ({children}: PropsWithChildren<{}>) => (
  <Centered>
    <Indicator>
      <Icon name="exclamation" size={40} color={'rgb(188, 0, 0)'} />
    </Indicator>
    {children ? (
      children
    ) : (
      <Message>There was an unexpected error. Please try again later</Message>
    )}
  </Centered>
);

export default Error;
