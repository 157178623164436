import React, {useContext} from 'react';
import {PlanSearchResult} from '../../api';
import {Text} from 'react-native';
import styled from 'styled-components/native';
import PlanResult from './PlanResult';
import {CompositeNavigationProp, useNavigation} from '@react-navigation/native';
import PlansContext from '../../contexts/PlansContext';
import {RootTabsParamList} from '../Routes';
import {StackNavigationProp} from '@react-navigation/stack/lib/typescript/src/types';
import {SearchStackParamList} from './SearchRoutes';
import {ListItem} from '../../styled-components/List';
import {DownloadStatus} from '../../hooks/usePlans';
import NotFound from '../general/NotFound';
import {Title} from '../../styled-components/Text';
import {BottomTabNavigationProp} from '@react-navigation/bottom-tabs/lib/typescript/src/types';

const ListTitle = styled(Text)`
  width: 100%;
  margin: 0 0 10px;
  color: #666;
`;

type SearchListNavigationProp = CompositeNavigationProp<
  StackNavigationProp<
    SearchStackParamList,
    'SearchList' | 'SecureDownload' | 'SearchHome'
  >,
  BottomTabNavigationProp<RootTabsParamList>
>;

interface ResultsListProps {
  searchTerm?: string;
  resultsResource: {read: () => PlanSearchResult[]};
  returnRoute: string;
}

const ResultsList = ({
  resultsResource,
  returnRoute,
  searchTerm = '',
}: ResultsListProps) => {
  const results = resultsResource.read();
  const navigation = useNavigation<SearchListNavigationProp>();
  const context = useContext(PlansContext);

  const navigateToPlan = (planId: string) => {
    navigation.reset({
      routes: [
        {
          name: 'Plans',
          state: {
            routes: [{name: 'PlansList'}, {name: 'Content', params: {planId}}],
          },
        },
      ],
    });
  };

  const handlePlanSelect = (
    result: PlanSearchResult,
    status: DownloadStatus,
  ) => {
    if (
      (status === 'ready' || status === 'error') &&
      (result.SearchTermPasswordRequired ||
        result.PreviewSearchTermPasswordRequired)
    ) {
      navigation.navigate('SecureDownload', {
        plan: result,
        returnRoute,
        searchTerm,
      });
    } else if (
      (status === 'ready' || status === 'error') &&
      !(
        result.SearchTermPasswordRequired ||
        result.PreviewSearchTermPasswordRequired
      )
    ) {
      context!.plans
        .download(result.PlanID)
        .catch((error) => context!.sendNotification('alert', error));
    } else if (status === 'downloaded') {
      navigateToPlan(result.PlanID);
    }
  };

  if (!results.length) {
    return (
      <NotFound>
        <Title>No Plans Found</Title>
        <Text>Try another code or ask your administrator</Text>
      </NotFound>
    );
  } else {
    return (
      <>
        <ListTitle>Found Plans</ListTitle>
        {results.map((result: PlanSearchResult) => {
          const status = context!.plans.downloadStatus(result.PlanID);
          return (
            <ListItem
              key={result.PlanID}
              onPress={() => handlePlanSelect(result, status)}>
              <PlanResult plan={result} status={status} />
            </ListItem>
          );
        })}
      </>
    );
  }
};

export default ResultsList;
