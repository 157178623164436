import React from 'react';
import {Text, TouchableOpacity} from 'react-native';
import styled from 'styled-components/native';
import Icon from 'react-native-vector-icons/FontAwesome';
import {
  windowIsWebDesktop,
  maxWidth,
} from '../../styled-components/windowIsWeb';

const ButtonWrapper = styled(TouchableOpacity)`
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 10px;
  background: white;
  border-radius: 5px;
  ${windowIsWebDesktop && `max-width: ${maxWidth.element}`}
`;

const Label = styled(Text)`
  margin: 0 15px;
`;

const NavIcon = styled(Icon)`
  margin-left: auto;
  margin-right: 15px;
`;

interface IconNavButtonProps {
  icon: Element;
  text: string;
  onPress: () => void;
}

const IconNavButton = ({text, onPress, icon}: IconNavButtonProps) => {
  return (
    <ButtonWrapper onPress={onPress}>
      {icon}
      <Label>{text}</Label>
      <NavIcon name="angle-right" size={32} color="black" />
    </ButtonWrapper>
  );
};

export default IconNavButton;
