import styled from 'styled-components/native';
import {View} from 'react-native';

const Centered = styled(View)`
  flex-grow: 1;
  justify-content: center;
  align-items: center;
`;

export default Centered;
