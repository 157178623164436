import {ObjectSchema} from 'realm';
import {Contact as ContactShape} from '../../api';

export class PhoneNumber {
  label?: string;
  digits: string;

  static schema: ObjectSchema = {
    name: 'PhoneNumber',
    properties: {
      label: 'string?',
      digits: 'string',
    },
  };
}

export class Contact {
  id: string;
  title?: string;
  name?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  phoneNumbers: PhoneNumber[];
  otherInfo?: string;
  company?: string;
  email?: string;

  static schema: ObjectSchema = {
    name: 'Contact',
    primaryKey: 'id',
    properties: {
      id: 'string',
      title: 'string',
      name: 'string?',
      address1: 'string?',
      address2: 'string?',
      city: 'string?',
      state: 'string?',
      postalCode: 'string?',
      phoneNumbers: 'PhoneNumber[]',
      otherInfo: 'string?',
      company: 'string?',
      email: 'string?',
    },
  };

  static apiToModel(data: ContactShape) {
    const phoneNumbers = [];

    if (data.Phone1) {
      phoneNumbers.push({
        label: data.Phone1Label,
        digits: data.Phone1,
      });
    }
    if (data.Phone2) {
      phoneNumbers.push({
        label: data.Phone2Label,
        digits: data.Phone2,
      });
    }
    if (data.Phone3) {
      phoneNumbers.push({
        label: data.Phone3Label,
        digits: data.Phone3,
      });
    }
    if (data.Phone4) {
      phoneNumbers.push({
        label: data.Phone4Label,
        digits: data.Phone4,
      });
    }

    return {
      id: data.ContactID,
      title: data.Title,
      name: data.Name,
      address1: data.Address1,
      address2: data.Address2,
      city: data.City,
      state: data.State,
      postalCode: data.PostalCode,
      phoneNumbers,
      otherInfo: data.OtherInfo,
      company: data.CompanyName,
      email: data.Email,
    };
  }
}
