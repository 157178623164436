// vector icons
import iconFontFontAwesome from './node_modules/react-native-vector-icons/Fonts/FontAwesome.ttf';
import iconFontFoundation from './node_modules/react-native-vector-icons/Fonts/Foundation.ttf';
import iconFontMaterialIcons from './node_modules/react-native-vector-icons/Fonts/MaterialIcons.ttf';
import iconFontIonicons from './node_modules/react-native-vector-icons/Fonts/Ionicons.ttf';

export default () => {

  // generate CSS for vector icons
  const iconFontStyles = `
    @font-face {
      src: url(${iconFontFontAwesome});
      font-family: FontAwesome;
    }
    @font-face {
      src: url(${iconFontFoundation});
      font-family: Foundation;
    }
    @font-face {
      src: url(${iconFontMaterialIcons});
      font-family: MaterialIcons;
    }
    @font-face {
      src: url(${iconFontIonicons});
      font-family: Ionicons;
    }
  `;
  // create stylesheet for vector icons
  const iconStyles = document.createElement('style');
  iconStyles.type = 'text/css';
  if (iconStyles.styleSheet) {
    iconStyles.styleSheet.cssText = iconFontStyles;
  } else {
    iconStyles.appendChild(document.createTextNode(iconFontStyles));
  }
  // inject vector icon stylesheet
  document.head.appendChild(iconStyles);
}
