import {PlanManager} from '../hooks/usePlans';
import {NotificationType} from '../hooks/useNotifications';
import {createContext} from 'react';
import DOA from '../database/doa.native';

export type PlansContextType = {
  doa: DOA;
  plans: PlanManager;
  sendNotification: (type: NotificationType, message: string) => void;
};

export default createContext<PlansContextType | null>(null);
