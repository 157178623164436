import React from 'react';
import {Contact} from '../../database/schemas/contact';
import {Card} from '../../styled-components/Card';
import IconLink from '../general/IconLink';
import {Row} from '../../styled-components/Row';
import {Field, ValueStack} from '../../styled-components/Field';
import {Light} from '../../styled-components/Text';

interface ContactListItemProps {
  contact: Contact;
}

const ContactListItem = ({contact}: ContactListItemProps) => (
  <Card>
    <Row border={true}>
      <Field>
        <ValueStack>
          {contact.name
            ? contact.name
            : contact.title
            ? contact.title
            : 'Contact'}
        </ValueStack>
        {!!contact.name && !!contact.title && <Light>{contact.title}</Light>}
      </Field>
      {!!contact.email && (
        <IconLink link={`mailto:${contact.email}`} icon="email" />
      )}
      {!!contact.phoneNumbers.length && (
        <IconLink link={`tel:${contact.phoneNumbers[0].digits}`} icon="phone" />
      )}
    </Row>
    <Row>
      <Light>
        {contact.email}
        {contact.email && contact.phoneNumbers.length && ' • '}
        {contact.phoneNumbers.length && contact.phoneNumbers[0].digits}
      </Light>
    </Row>
  </Card>
);

export default ContactListItem;
