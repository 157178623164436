import React, {useRef, useState} from 'react';
import styled from 'styled-components/native';
import {Animated, Text, TouchableOpacity, View} from 'react-native';
import {FunctionComponent} from 'react';
import Icon from 'react-native-vector-icons/MaterialIcons';
import {windowIsWebDesktop, maxWidth} from '../../styled-components/windowIsWeb';

const ActionSheetOverlay = styled(TouchableOpacity)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
`;

const ActionSheetWrapper = styled(View)`
  position: absolute;
  bottom: 30px;
  width: 100%;
  flex-direction: row;
  justify-content: center;
`;

const ActionSheetInnerWrapper = styled(Animated.View)`
  justify-content: center;
  width: ${windowIsWebDesktop
    ? maxWidth.element
    : '90%'
  };
  padding: 0 20px 20px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
`;

const DragHandle = styled(View)`
  flex-direction: row;
  justify-content: center;
  background: white;
`;

const DragHandleGrip = styled(View)`
  width: 32px;
  height: 5px;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.2);
  margin: 10px 0 15px;
`;

const CloseButton = styled(TouchableOpacity)`
  flex-direction: row;
  justify-content: center;
  margin: 20px 0 0;
`;

interface ActionSheetProps {
  close: () => void;
}

const ActionSheet: FunctionComponent<ActionSheetProps> = ({
  close,
  children,
}) => {
  const [initialGripPageY, setInitialGripPageY] = useState<
    number | undefined
  >();
  const gripY = useRef(new Animated.Value(0)).current;

  const handleGripMove = (evt: {nativeEvent: {pageY: number}}) => {
    if (initialGripPageY === undefined) {
      setInitialGripPageY(evt.nativeEvent.pageY);
    } else {
      const y = evt.nativeEvent.pageY - initialGripPageY!;
      if (y > 0) {
        gripY.setValue(Math.abs(y));
      }
    }
  };

  const handleGripRelease = () => {
    gripY.stopAnimation((val) => {
      if (val > 100) {
        close();
      } else {
        gripY.setValue(0);
        setInitialGripPageY(undefined);
      }
    });
  };

  return (
    <>
      <ActionSheetOverlay onPress={close} />
      <ActionSheetWrapper>
        <ActionSheetInnerWrapper style={{transform: [{translateY: gripY}]}}>
          <DragHandle
            onStartShouldSetResponder={() => true}
            onResponderMove={handleGripMove}
            onResponderRelease={handleGripRelease}>
            <DragHandleGrip />
          </DragHandle>
          {children}
          <CloseButton onPress={close}>
            <Text>
              <Icon name="close" size={30} color="#666666" />
            </Text>
          </CloseButton>
        </ActionSheetInnerWrapper>
      </ActionSheetWrapper>
    </>
  );
};

export default ActionSheet;
