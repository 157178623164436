import styled from 'styled-components/native';
import {Platform, SafeAreaView} from 'react-native';

interface SafeAreaProps {
  background?: string | symbol;
}

export const SafeArea = styled(SafeAreaView)<SafeAreaProps>`
  padding-top: ${Platform.OS === 'ios' ? '0' : '40px'};
  background: ${(props) =>
    props.background ? props.background : props.theme.primary};
`;
