import styled from 'styled-components/native';
import {View} from 'react-native';
import {windowIsWebDesktop, maxWidth} from './windowIsWeb';

const Screen = styled(View)`
  width: 100%;
  flex-grow: 1;
  align-items: center;
  padding: 40px 20px 150px;
  ${windowIsWebDesktop && `
    max-width: ${maxWidth.container};
    align-self: center;
    padding: 40px 0;
  `}
`;

export default Screen;
