import React, {useContext} from 'react';
import {createStackNavigator} from '@react-navigation/stack';
import Header from '../general/Header';
import PlanList from './PlanList';
import ContactList from './ContactList';
import PlansContext from '../../contexts/PlansContext';
import Contact from './Contact';
import {Plan} from '../../database/schemas/plan';

export type ContactsStackParamList = {
  PlanList: undefined;
  ContactList: {planId: string};
  Contact: {planId: string; contactId: string};
};

const Stack = createStackNavigator<ContactsStackParamList>();

const ContactRoutes = () => {
  const context = useContext(PlansContext);

  return (
    <Stack.Navigator
      initialRouteName="PlanList"
      headerMode="screen"
      screenOptions={{header: (props) => <Header {...props} />}}>
      <Stack.Screen name="PlanList" component={PlanList} />
      <Stack.Screen
        name="ContactList"
        component={ContactList}
        options={({route}) => ({
          headerBackTitle: context!.doa.getDocument<Plan>(
            'Plan',
            route.params.planId,
          )!.name,
        })}
      />
      <Stack.Screen
        name="Contact"
        component={Contact}
        options={{headerBackTitle: ''}}
      />
    </Stack.Navigator>
  );
};

export default ContactRoutes;
