import {Platform} from 'react-native';
import styled from 'styled-components/native';

export const ScrollView = styled.ScrollView`
  ${Platform.OS === 'web' && `
    flex: 1;
    overflowY: auto;
  `}
`;

export default ScrollView;