import React, {PropsWithChildren} from 'react';
import styled from 'styled-components/native';

interface LinearGradientWebProps {
  children: (string | number)[];
  colors: (string | number)[];
  [key: string]: any;
}

const LinearGradientWeb = styled.View`
  position: absolute;
  bottom: 0;
  height: 15%;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  background-image: linear-gradient(
    ${(props: LinearGradientWebProps) => props.colors[0]},
    ${(props: LinearGradientWebProps) => props.colors[1]}
  );
`;

const WebLinearGradient = (data: PropsWithChildren<LinearGradientWebProps>) => {
  return <LinearGradientWeb {...data} />;
};

export default WebLinearGradient;
