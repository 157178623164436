import React, { useState } from 'react';
import Pdf from 'react-native-pdf';
import { Document as PdfDocument, Page as PdfPage, pdfjs } from 'react-pdf';
import styled from 'styled-components';
import { contentUrl, isWeb } from '../../../api';
import { ScrollView } from '../../general/ScrollView';
import {windowIsWeb, windowIsWebDesktop} from '../../../styled-components/windowIsWeb';

// TODO: move this to our own CDN but this should be safe for now
pdfjs.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.5.207/pdf.worker.min.js';

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  ${windowIsWeb && !windowIsWebDesktop &&
  `
    padding-bottom: 142px;
  `};
  ${windowIsWeb && windowIsWebDesktop &&
  `
    padding-bottom: 54px;
  `};
`;

const PaginationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 16rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

const MobilePdfWrapper = styled(Pdf)`
  flex-grow: 1;
  width: 100%;
`;

interface PDFContentProps {
  content: any;
  planId: string;
  contentSignature: string;
}

const PDFContent = ({
  content,
  planId,
  contentSignature,
}: PDFContentProps) => {
  const pdfUrl = `${contentUrl}/${planId}/${content.contentID}/${content.file!}${contentSignature}`;
  const [numPages, setNumPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  const previousPage = () => setPageNumber(pageNumber - 1);
  const nextPage = () => setPageNumber(pageNumber + 1);

  const pagination = () => {
    if (numPages <= 1) return null;

    return (
      <PaginationWrapper>
        <button
          type="button"
          disabled={pageNumber <= 1}
          onClick={previousPage}
        >
          Previous
        </button>
        <span>
          Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
        </span>
        <button
          type="button"
          disabled={pageNumber >= numPages}
          onClick={nextPage}
        >
          Next
        </button>
      </PaginationWrapper>
    );
  };

  if (isWeb) {
    return (
      <ScrollView>
        <ContentWrapper>
          {pagination()}
          <PdfDocument file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
            <PdfPage pageNumber={pageNumber} />
          </PdfDocument>
          {pagination()}
        </ContentWrapper>
      </ScrollView>
    );
  }

  return (
    <MobilePdfWrapper
      source={{
        uri: pdfUrl,
      }}
      fitPolicy={0}
      style={{
        backgroundColor: 'rgb(241, 243, 254)',
        paddingBottom: 100,
      }}
    />
  );
};

export default PDFContent;
