import React from 'react';

interface PdfProps {
  source: {
    uri: string;
  };
}

const Pdf = ({source}: PdfProps) => {
  return (
    <object
      type="application/pdf"
      data={source.uri}
      width="100%"
      height="100%"></object>
  );
};

export default Pdf;
