import React, {useContext} from 'react';
import PlansContext from '../../../contexts/PlansContext';
import {ListContent as ListContentType} from '../../../database/schemas/content';
import {useNavigation} from '@react-navigation/core';
import Screen from '../../../styled-components/Screen';
import {ScrollView} from '../../general/ScrollView';
import {PlanStackParamList} from '../PlanRoutes';
import {StackNavigationProp} from '@react-navigation/stack/lib/typescript/src/types';
import {
  Background,
  IconWrapper,
  isWhite,
  ListContentItem,
  TextWrapper,
} from '../../../styled-components/ListItem';

interface ListContentProps {
  planId: string;
  contentId: string;
}

const ListContent = ({planId, contentId}: ListContentProps) => {
  const navigation = useNavigation<StackNavigationProp<PlanStackParamList>>();
  const context = useContext(PlansContext);
  const contents = context!.doa.getContent(planId, contentId);

  return (
    <ScrollView>
      <Screen>
        {contents!.listContents.map((content: ListContentType) => (
          <ListContentItem
            key={content.listContentID}
            onPress={() =>
              navigation.push('Content', {
                planId: planId,
                contentId: content.nextPlanContentID,
              })
            }>
            <Background background={content.backgroundColor || 'black'} />
            <TextWrapper>{content.title}</TextWrapper>
            <IconWrapper
              background={content.backgroundColor || 'black'}
              name="arrow-forward"
              size={24}
              color={
                isWhite(content.backgroundColor)
                  ? 'rgba(0, 0, 0, 0.8)'
                  : 'white'
              }
            />
          </ListContentItem>
        ))}
      </Screen>
    </ScrollView>
  );
};

export default ListContent;
