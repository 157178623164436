import React from 'react';
import styled from 'styled-components/native';
import {Linking, TouchableOpacity, View} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';

const IconWrapper = styled(View)`
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 25px;
  background: ${(props) => props.theme.primary};
  margin: 0 5px;
`;

interface IconLinkProps {
  link: string | (() => void);
  icon: string;
}

const IconLink = ({link, icon}: IconLinkProps) => (
  <TouchableOpacity
    onPress={typeof link === 'string' ? () => Linking.openURL(link) : link}>
    <IconWrapper>
      <Icon name={icon} size={16} color="white" />
    </IconWrapper>
  </TouchableOpacity>
);

export default IconLink;
