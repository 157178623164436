import React, {useState} from 'react';
import Centered from '../styled-components/Centered';
import Screen from '../styled-components/Screen';
import {SafeArea} from '../styled-components/SafeArea';
import {Header, HeaderWrapper, Title} from '../styled-components/Header';
import styled from 'styled-components/native';
import Icon from 'react-native-vector-icons/FontAwesome';
import {TouchableOpacity, View} from 'react-native';
import Torch from 'react-native-torch';

type PowerButtonProps = {
  on: boolean;
};

const PowerButton = styled(TouchableOpacity)<PowerButtonProps>`
  padding: 40px;
  background: white;
  border-radius: 100px;
`;

const IntesityIndicator = styled(View)<PowerButtonProps>`
  border-radius: 150px;
  border-width: 15px;
`;

const IntesityIndicatorWeak = styled(IntesityIndicator)`
  border-color: ${(props) =>
    props.on ? 'rgb(247, 0, 0)' : 'rgb(196, 206, 240)'};
`;

const IntesityIndicatorMedium = styled(IntesityIndicator)`
  border-color: ${(props) =>
    props.on ? 'rgb(252, 83, 80)' : 'rgb(175, 182, 211)'};
`;

const IntesityIndicatorStrong = styled(IntesityIndicator)`
  border-color: ${(props) =>
    props.on ? 'rgb(248, 140, 144)' : 'rgb(218, 225, 248)'};
`;

const Flashlight = () => {
  const [on, setOn] = useState(false);

  const handlePowerToggle = () => {
    Torch.switchState(!on);
    setOn(!on);
  };

  return (
    <>
      <SafeArea>
        <HeaderWrapper />
      </SafeArea>
      <Header>
        <Title>Flashlight</Title>
      </Header>
      <Screen>
        <Centered>
          <IntesityIndicatorStrong on={on}>
            <IntesityIndicatorMedium on={on}>
              <IntesityIndicatorWeak on={on}>
                <PowerButton onPress={handlePowerToggle} on={on}>
                  <Icon
                    name="power-off"
                    size={40}
                    color={'rgb(95, 105, 142)'}
                  />
                </PowerButton>
              </IntesityIndicatorWeak>
            </IntesityIndicatorMedium>
          </IntesityIndicatorStrong>
        </Centered>
      </Screen>
    </>
  );
};

export default Flashlight;
