import React, {PropsWithChildren, useEffect, useRef, useState, useContext} from 'react';
import styled from 'styled-components';
import {maxWidth} from '../../styled-components/windowIsWeb';
import {ContentData, getContentHtml, simplifyContentId} from '../../utils/planFormEmail';
import {windowIsWeb, windowIsWebDesktop} from '../../styled-components/windowIsWeb';
import PlansContext from '../../contexts/PlansContext';
interface WebViewProps {
  source?: any;
  onLoad?: () => void;
  paddingBottom?: string;
}

const ScrollWrapper = styled.div`
  position: relative;
  display:flex;
  flex-direction: column;
  flex: 1;
`;

const ContentWrapper = styled.div<WebViewProps>`
  display: flex;
  justify-content: center;
  align-items: stretch;
  padding-left: 16px;
  padding-right: 16px;
  ${windowIsWeb && !windowIsWebDesktop &&
  `
    padding-bottom: 142px;
  `};
  ${windowIsWeb && windowIsWebDesktop &&
  `
    padding-bottom: 54px;
  `};
`;

const Content = styled.div`
  max-width: ${maxWidth.container};
`;

const ContentBody = styled.div`
  width: 100%;
`;

const CustomEmailFields = styled.div`
  display: none;
  flex-direction: column;
  margin: 16px 0 124px;
  padding: 8px 22px;
  box-shadow: inset 0 0 0 2px rgba(0,0,0,0.05);
  background: rgba(0, 105, 146, 0.05);

  &.is-visible {
    display: flex;
  }

  p {
    margin-bottom: 12px;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.44;
    opacity: 0.9;
  }

  label {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    font-size: 13px;
    font-weight: 600;

    span {
      display: flex;
      &.title {
        align-items: flex-end;
        .small {
          margin-left: 10px;
          margin-bottom: 1px;
          font-size: 10px;
          opacity: 0.6;
        }
      }
    }

    .input-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 4px;
      padding:4px;
      border-radius: 2px;
      box-shadow:0 0 0 1px rgba(0,0,0,0.1);
      background: #fff;
      cursor: text;

      .static-addresses {
        display: none;
        margin-bottom: 4px;
        padding: 4px 6px;
        font-weight: 500;
        font-size: 11px;
        border-radius: 2px;
        background: rgba(0,0,0,0.1);
        opacity: 0.8;
        cursor: default;
      }

      input {
        border: none;
        width: 96%;
        padding: 4px 4px;
        &::placeholder { opacity: 0.6; }
      }

    }
  }
`;

export const WebView = ({
  source: sourceRoot,
  paddingBottom,
}: PropsWithChildren<WebViewProps>) => {
  const context = useContext(PlansContext);
  // find saved content via id if exists
  const contentId = simplifyContentId(sourceRoot.uri);
  let savedContent = window.localStorage.getItem(contentId);
  // define links to template elements
  let customEmailStaticAddresses = useRef<HTMLElement | null>().current;
  let customEmailToInput = useRef<HTMLElement | null>().current;
  let customEmailFromInput = useRef<HTMLElement | null>().current;
  let contentBody = useRef<HTMLElement | null>().current;
  // include form submit email section or not
  const [planHasForm, setPlanHasForm] = useState(false);
  useEffect(() => {
    // if react is aware of the render container
    if (contentBody) {
      // prevent infinite appending
      contentBody.innerHTML = '';
      // helper function appends contentBody to ui
      const appendContent = (html: string) => {
        if (contentBody) {
          // construct data object to construct DOM and functions
          const contentData: ContentData = {
            contentBody,
            id: contentId,
            parsedContent: new window.DOMParser().parseFromString(
              html,
              'text/html',
            ),
            source: html,
            customEmailToInput,
            customEmailFromInput,
            customEmailStaticAddresses,
            sendNotification: (type: 'alert' | 'info', message: string) => context!.sendNotification(type, message),
            setPlanHasForm
          };
          // add saved content to ui contentBody
          const sourceContent = getContentHtml(contentData);
          if (sourceContent) contentBody.append(sourceContent);
        }
      };
      // If saved source is found in localstorage
      // it's assumed to be a string of a simple html page (about.html, t&c.html)
      // otherwise source is an object with a url, fetch from server.
      savedContent
        ? appendContent(savedContent)
        : typeof sourceRoot === 'string'
        ? appendContent(sourceRoot)
        : sourceRoot.uri
        ? fetch(sourceRoot.uri)
            .then((resp) => resp.text())
            .then((resp) => appendContent(resp))
        : '';
    }
  }, [
    contentBody,
    customEmailToInput,
    customEmailFromInput
  ]);

  return (
    <ScrollWrapper>
      <ContentWrapper paddingBottom={paddingBottom}>
        <Content>

          <ContentBody ref={(ref: HTMLElement) => contentBody = ref} />

          <CustomEmailFields className={planHasForm ? 'is-visible' : ''}>
            <p>Send the form to additional people. (Comma-separate multiple emails)</p>
            <label>
              <span className="title">
                Send To:
              </span>
              <div className="input-wrapper">
                <div className="static-addresses" ref={(ref: HTMLElement) => customEmailStaticAddresses = ref}></div>
                <input type="text" ref={(ref: HTMLElement) => customEmailToInput = ref}
                  placeholder="-- Additional TO email addresses --" />
              </div>
            </label>

            <p>Let receivers know who sent the form. (Comma-separate multiple emails)</p>
            <label>
              <span className="title">
                Sent From: <span className="small">(optional)</span>
              </span>
              <div className="input-wrapper">
                <input type="text" ref={(ref: HTMLElement) => customEmailFromInput = ref}
                  placeholder="-- Additional FROM email addresses --" />
              </div>
            </label>
          </CustomEmailFields>

        </Content>
      </ContentWrapper>
    </ScrollWrapper>
  );
};

export default WebView;
