import React, {Suspense, useEffect, useState, useRef} from 'react';
import InputBox, {TextInput} from '../general/InputBox';
import {
  NativeSyntheticEvent,
  TextInputChangeEventData,
  ScrollView,
} from 'react-native';
import debounce from 'lodash/debounce';
import ResultsList from './ResultsList';
import api, {PlanSearchResult} from '../../api';
import {handlePending, SuspenseResource} from '../../utils/suspense';
import Screen from '../../styled-components/Screen';
import {useSuspense} from '../../hooks/useSuspense';
import {Header} from '../../styled-components/Header';
import Error from '../general/Error';
import ErrorBoundary from '../general/ErrorBoundary';

const SearchList = () => {
  const [results, setResults] = useState<
    SuspenseResource<PlanSearchResult[]>
  >();
  // Discriminating "preview" plans requires comparing
  // the search term with the result data (PreviewSearchTerm)
  // so we have to pass it through to the results list.
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [pending] = useSuspense(results);
  
  // autoFocus only works first time component mounts.
  // Need to explicitly focus when page loads subsequent times.
  let orgCodeTextInput = useRef<HTMLInputElement | null>().current;
  useEffect(() => orgCodeTextInput?.focus() );

  const handleChange = debounce((value: string) => {
    if (value) {
      setSearchTerm(value);
      setResults(handlePending(api.searchPlans(value)));
    } else {
      setSearchTerm('');
      setResults(undefined);
    }
  }, 300);

  return (
    <>
      <Header>
        <InputBox
          background="rgba(0, 0, 0, 0.2)"
          color="white"
          icon="search"
          pending={pending}>
          <TextInput
            ref={(ref: HTMLInputElement) => orgCodeTextInput = ref}
            autoCapitalize="none"
            color="white"
            placeholder="Enter organization code"
            placeholderTextColor="white"
            autoFocus={true}
            autoCorrect={false}
            onChange={(e: NativeSyntheticEvent<TextInputChangeEventData>) =>
              handleChange(e.nativeEvent.text)
            }
          />
        </InputBox>
      </Header>
      <ScrollView>
        <Screen>
          <ErrorBoundary fallback={<Error />}>
            <Suspense fallback={<></>}>
              {results && (
                <ResultsList
                  searchTerm={searchTerm}
                  returnRoute="SearchList"
                  resultsResource={results!}
                />
              )}
            </Suspense>
          </ErrorBoundary>
        </Screen>
      </ScrollView>
    </>
  );
};

export default SearchList;
