import React, { useContext } from 'react';
import {RouteProp} from '@react-navigation/core';
import PlansContext from '../../../contexts/PlansContext';
import {PlanStackParamList} from '../PlanRoutes';
import {Header, Title} from '../../../styled-components/Header';
import WebView from '../../general/WebView';
import {ScrollView} from '../../general/ScrollView';
import {Contents} from '../../../database/schemas/content';
import Audio from '../../general/Audio';
import {plansDir} from '../../../hooks/usePlans';
import { contentUrl, isWeb } from '../../../api';
import { useContentSignature } from '../../ContentSignature';
import ExpandableContent from './ExpandableContent';
import ListContent from './ListContent';
import PDFContent from './PDFContent';

type PlanRouteProp = RouteProp<PlanStackParamList, 'Content'>;

interface ContentProps {
  route: PlanRouteProp;
}

const Content = ({route}: ContentProps) => {
  const {planId, contentId} = route.params;
  const context = useContext(PlansContext);
  const headerColors = Contents.headerColors(context!.doa, contentId);
  const content = context!.doa.getContent(planId, contentId);
  const { contentSignature } = useContentSignature();

  if (!content) {
    return (
      <Header background={headerColors.background}>
        <Title color={headerColors.tint}>Not Found</Title>
      </Header>
    );
  } else {
    return (
      <>
        <Header background={headerColors.background}>
          <Title color={headerColors.tint}>{content.title}</Title>
        </Header>
        {content.type === 'L' && (
          <ListContent planId={planId} contentId={content.contentID} />
        )}
        {content.type === 'E' && (
          <ExpandableContent planId={planId} contentId={content.contentID} />
        )}
        {content.type === 'H' && (
          <ScrollView>
            <WebView
              source={{
                uri: `${contentUrl}/${planId}/${
                  content.contentID
                }/${content.indexFile!}${contentSignature}`,
              }}
            />
          </ScrollView>
        )}
        {content.type === 'F' && (
          <PDFContent
            content={content}
            planId={planId}
            contentSignature={contentSignature}
          />
        )}
        {content.type === 'A' && (
          <Audio
            source={{
              id: content.file!,
              title: content.title,
              artist: 'Artist',
              url: `${contentUrl}/${planId}/${
                content.contentID
              }/${content.file!}${contentSignature}`,
            }}
          />
        )}
      </>
    );
  }
};

export default Content;
