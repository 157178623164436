import React, {useContext, useState} from 'react';
import {View, Text, Modal} from 'react-native';
import {BottomTabBarProps} from '@react-navigation/bottom-tabs/lib/typescript/src/types';
import styled, {ThemeContext} from 'styled-components/native';
import ActionSheet from './ActionSheet';
import TabButton from '../../styled-components/TabButton';
import ActionButton from '../../styled-components/ActionButton';
import Icon from 'react-native-vector-icons/MaterialIcons';
import {windowIsWebDesktop, windowIsWebTablet} from '../../styled-components/windowIsWeb';

const labelColor = '#000000';
const iconColor = '#aaaaaa';
const iconSize = windowIsWebDesktop
  ? 18
  : 28;
const secondaryTabsOffset = 3;

const MainTabBarWrapper = styled(View)`
  position: absolute;
  bottom: 30px;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  ${windowIsWebDesktop && `
    justify-content: flex-start;
    bottom: unset;
    top: 0;
    left: 0;
  `}
`;

const MainTabBarInnerWrapper = styled(View)`
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 240px;
  padding: 10px 12px;
  background: white;
  border-radius: 50px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  elevation: 3;
  ${windowIsWebDesktop && `
    justify-content: center;
    flex-wrap: wrap;
    gap: 14px;
    width: 100%;
    max-width: 100%;
    padding-left: 52px;
    border-radius: 0;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.9);
    background: #1a1a1a;
  `}
  ${windowIsWebTablet && `padding-left: 24px;`}
`;

const MainLinkWeb = styled(Text)`
  ${windowIsWebDesktop && `
    display: flex;
    align-items: center;
    margin-left:  12px;
    margin-right: 54px;
  `}
  ${windowIsWebTablet && `margin-right: 24px;`}
`;

const MainLinkWebText = styled(Text)<{color: string}>`
  color: ${(props) => props.color ? props.color : '#888'};
  ${windowIsWebDesktop && `
    display: flex;
    align-items: center;
    margin-left: 12px;
    font-size: 14px;
    font-weight: 600;
    padding:0;
  `}
`;

const SecondaryLinkWebText = styled(MainLinkWebText)`
  ${windowIsWebDesktop && `
    margin-left:0;
  `}
`;

const SecondaryLinkText = styled(Text)`
  ${windowIsWebDesktop && `
    font-size: 16px;
    font-weight: 600;
  `}
`;

const SecondaryLinkIcon = styled(Text)`
  ${windowIsWebDesktop && `
    padding-left: 12px;
  `}
`;

const MoreIcon = styled(View)`
  background: ${iconColor};
  border-radius: 50px;
`;

const MoreIconText = styled(Text)`
  ${windowIsWebDesktop && `
    display: flex;
    align-items: center;
  `}
`;

const MainTabBar = ({state, descriptors, navigation}: BottomTabBarProps) => {
  const {primary} = useContext<{primary: string}>(ThemeContext);
  const [secondaryTabsOpen, setSecondaryTabsOpen] = useState(false);

  // Slicing state.routes allows us to add navigation tabs
  // to the secondary menu without modifying the MainTabBar component
  const mainTabs = state.routes
    .slice(0, secondaryTabsOffset)
    .map((route, index) => {
      const isFocused = state.index === index;
      const color = isFocused ? primary : iconColor;
      const linkTextColor = isFocused ? '#FFFFFF' : iconColor;
      const {options} = descriptors[route.key];
      const label =
        options.tabBarIcon !== undefined
          ? options.tabBarIcon({
              color,
              focused: isFocused,
              size: iconSize,
            })
          : options.tabBarLabel !== undefined
          ? options.tabBarLabel
          : options.title !== undefined
          ? options.title
          : route.name;

      const onPress = () => {
        const event = navigation.emit({
          type: 'tabPress',
          target: route.key,
          canPreventDefault: true,
        });

        if (!isFocused && !event.defaultPrevented) {
          navigation.navigate(route.name);
        }
      };

      return (
        <TabButton
          key={route.key}
          accessibilityRole="button"
          accessibilityState={isFocused ? {selected: true} : {}}
          accessibilityLabel={options.tabBarAccessibilityLabel}
          testID={options.tabBarTestID}
          onPress={onPress}>
          {windowIsWebDesktop
            ? <MainLinkWeb>
                {label}
                <MainLinkWebText color={linkTextColor}>{route.name}</MainLinkWebText>
              </MainLinkWeb>
            : <Text>{label}</Text>
          }
        </TabButton>
      );
    });

  const secondaryTabs = state.routes
    .slice(secondaryTabsOffset)
    .map((route, index) => {
      // Offsetting the index of secondaryTabs to match state.index
      // because we sliced state.routes
      const isFocused = state.index === index + secondaryTabsOffset;
      const secondaryLinkTextColor = isFocused ? '#FFFFFF' : iconColor;
      const {options} = descriptors[route.key];
      const label =
        options.tabBarLabel !== undefined
          ? options.tabBarLabel
          : options.title !== undefined
          ? options.title
          : route.name;

      const onPress = () => {
        const event = navigation.emit({
          type: 'tabPress',
          target: route.key,
          canPreventDefault: true,
        });

        setSecondaryTabsOpen(false);

        if (!isFocused && !event.defaultPrevented) {
          navigation.navigate(route.name);
        }
      };

      const SecondaryButton = windowIsWebDesktop ? TabButton : ActionButton;
      const SecondaryIcon = windowIsWebDesktop ? SecondaryLinkIcon : Text;

      return (
        <SecondaryButton
          key={route.key}
          accessibilityRole="button"
          accessibilityState={isFocused ? {selected: true} : {}}
          accessibilityLabel={options.tabBarAccessibilityLabel}
          testID={options.tabBarTestID}
          onPress={onPress}>
          {windowIsWebDesktop
            ? <MainLinkWeb><SecondaryLinkWebText color={secondaryLinkTextColor}>{label}</SecondaryLinkWebText></MainLinkWeb>
            : <SecondaryLinkText style={{color: isFocused ? primary : labelColor}}>{label}</SecondaryLinkText>
          }
          {options.tabBarIcon && (
            <SecondaryIcon>
              {options.tabBarIcon({
                size: iconSize,
                color: isFocused ? primary : iconColor,
                focused: isFocused,
              })}
            </SecondaryIcon>
          )}
        </SecondaryButton>
      );
    });

  return (
    <>
      <MainTabBarWrapper>
        <MainTabBarInnerWrapper>
          {mainTabs}
          {windowIsWebDesktop
            ? secondaryTabs
            : <TabButton
                accessibilityRole="button"
                onPress={() => setSecondaryTabsOpen(true)}>
                <MoreIcon>
                  <MoreIconText>
                    <Icon name="more-horiz" size={iconSize} color="#ffffff" />
                  </MoreIconText>
                </MoreIcon>
              </TabButton>
          }
        </MainTabBarInnerWrapper>
      </MainTabBarWrapper>
      {secondaryTabsOpen && (
        <Modal
          animationType={'slide'}
          transparent={true}
          statusBarTranslucent={true}
          visible={secondaryTabsOpen}
          onRequestClose={() => setSecondaryTabsOpen(false)}>
          <ActionSheet close={() => setSecondaryTabsOpen(false)}>
            {secondaryTabs}
          </ActionSheet>
        </Modal>
      )}
    </>
  );
};

export default MainTabBar;
