import React from 'react';
import {View, Text} from 'react-native';
import styled from 'styled-components/native';

interface DividerDisplayProps {
  color: string;
  lineColor: string;
  background: string;
}

const DividingLine = styled(View)<{lineColor: string}>`
  flex-direction: row;
  width: 100%;
  max-width: 350px;
  height: 40px;
  margin-top: 40px;
  border-top-width: 1px;
  border-top-color: ${(props) => props.lineColor};
  border-style: solid;
  justify-content: center;
`;

const DividingText = styled(Text)<{
  color: string;
  background: string;
}>`
  transform: translateY(-10px);
  padding: 0 8px;
  color: ${(props) => props.color};
  background: ${(props) => props.background};
`;

interface DividerProps extends DividerDisplayProps {
  text?: string;
}

const Divider = ({text, color, lineColor, background}: DividerProps) => {
  return (
    <DividingLine lineColor={lineColor}>
      {text && (
        <DividingText color={color} background={background}>
          {text}
        </DividingText>
      )}
    </DividingLine>
  );
};

export default Divider;
