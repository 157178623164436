import styled from 'styled-components/native';
import {TouchableOpacity} from 'react-native';
import {windowIsWebDesktop} from './windowIsWeb';

const TabButton = styled(TouchableOpacity)`
  text-align: center;
  ${windowIsWebDesktop && `
    display:flex;
    flex-direction: row-reverse;
  `}
`;

export default TabButton;
