import React from 'react';
import {ActivityIndicator} from 'react-native';
import Centered from '../../styled-components/Centered';

const CenteredIndicator = () => {
  return (
    <Centered>
      <ActivityIndicator color="black" />
    </Centered>
  );
};

export default CenteredIndicator;
