// @ts-ignore
import DOMParser from 'react-native-html-parser';
import RNFS from 'react-native-fs';

const parser = new DOMParser.DOMParser();
const serializer = new DOMParser.XMLSerializer();

export const formToEmail = (source: string, data: any) => {
  const parsed = parser.parseFromString(source, 'text/html');

  for (let name in data) {
    // Ignore email address, subject and empty values
    if (
      name !== 'emailAddresses' &&
      name !== 'subject' &&
      name !== '' &&
      name !== 'button'
    ) {
      const inputs = parsed.getElementsByAttribute('name', name);
      if (inputs.length === 1) {
        const item = inputs.item(0);
        const type = item.getAttribute('type');
        if (type === 'text' || item.tagName === 'textarea') {
          const value = parsed.createTextNode(data[name]);
          item.parentNode.replaceChild(value, item);
        } else if (type === 'checkbox') {
          const value = parsed.createTextNode('✔');
          item.parentNode.replaceChild(value, item);
        }
      } else if (inputs.length > 1) {
        for (let i = 0; i < inputs.length; i++) {
          const item = inputs.item(i);
          const value = parsed.createTextNode('✔');
          if (item.getAttribute('value') === data[name]) {
            item.parentNode.replaceChild(value, item);
          }
        }
      }
    }
  }

  const inputs = parsed.getElementsByTagName('input');

  for (let i = 0; i < inputs.length; i++) {
    const item = inputs.item(i);
    const type = item.getAttribute('type');
    if (type === 'checkbox') {
      const value = parsed.createTextNode('▢');
      item.parentNode.replaceChild(value, item);
      --i;
    }
  }

  const parent = parsed.getElementsByTagName('button').item(0).parentNode;
  parent.parentNode.removeChild(parent);

  return serializer.serializeToString(parsed.documentElement);
};

export const injectFormValues = (source: string, data: any) => {
  const parsed = parser.parseFromString(source, 'text/html');

  for (let name in data) {
    // Ignore email address, subject and empty values
    if (
      name !== 'emailAddresses' &&
      name !== 'subject' &&
      name !== '' &&
      name !== 'button'
    ) {
      const inputs = parsed.getElementsByAttribute('name', name);
      if (inputs.length === 1) {
        const item = inputs.item(0);
        const type = item.getAttribute('type');
        if (type === 'text') {
          item.setAttribute('value', data[name]);
        } else if (item.tagName === 'textarea') {
          const value = parsed.createTextNode(data[name]);
          if (item.hasChildNodes()) {
            item.replaceChild(value, item.firstChild);
          } else {
            item.appendChild(value);
          }
        } else if (type === 'checkbox') {
          item.setAttribute('checked', 'true');
        }
      } else if (inputs.length > 1) {
        for (let i = 0; i < inputs.length; i++) {
          if (inputs.item(i).getAttribute('value') === data[name]) {
            inputs.item(i).setAttribute('checked', 'true');
          }
        }
      }
    }
  }
  return serializer.serializeToString(parsed.documentElement);
};

export const saveForm = async (body: string, file: string) => {
  const exists = await RNFS.exists(`${file}.original`);
  if (!exists) {
    await RNFS.moveFile(file, `${file}.original`);
  }
  await RNFS.writeFile(
    file,
    `
      <!DOCTYPE html>
      <html>
      <head>
          <meta http-equiv="Content-Type" content="charset=utf-8">
      </head>
      <body>${body}</body>
      </html>
    `,
  );
};

export const resetForm = async (file: string) => {
  const exists = await RNFS.exists(`${file}.original`);
  if (exists) {
    await RNFS.unlink(file);
    await RNFS.moveFile(`${file}.original`, file);
  }
};
