import {Platform} from 'react-native';
const appType = Platform.OS === 'web' ? 'Web' : 'Mobile';

export default {
  appName: '', // Default app name is for My-EOP
  eulaTitle: 'Welcome to My-EOP',
  eulaInstructions:
    `Please accept the End-User License Agreement (EULA) before entering the My-EOP ${appType} App`,
  copyright: 'Copyright 2020, GCC',
  theme: {
    statusBar: 'rgba(254, 0, 0, 0)',
    primary: 'rgb(254, 0, 0)',
  },
};
