import React from 'react';
import {
  CardStyleInterpolators,
  createStackNavigator,
} from '@react-navigation/stack';
import SearchHome from './SearchHome';
import SearchList from './SearchList';
import SecureDownload from './SecureDownload';
import Icon from 'react-native-vector-icons/MaterialIcons';
import Header from '../general/Header';
import {PlanSearchResult} from '../../api';
import Samples from './Samples';

export type SearchStackParamList = {
  SearchHome: undefined;
  SearchList: undefined;
  Samples: undefined;
  SecureDownload: {
    plan: PlanSearchResult;
    returnRoute: string;
  };
};

const Stack = createStackNavigator<SearchStackParamList>();

const SearchRoutes = () => (
  <Stack.Navigator
    headerMode="screen"
    screenOptions={{header: (props) => <Header {...props} />}}>
    <Stack.Screen name="SearchHome" component={SearchHome} />
    <Stack.Screen
      name="SearchList"
      component={SearchList}
      options={{
        headerBackTitle: 'Find a Plan',
        headerBackImage: () => <Icon name="close" size={28} color="white" />,
        cardStyleInterpolator: CardStyleInterpolators.forVerticalIOS,
      }}
    />
    <Stack.Screen name="Samples" component={Samples} />
    <Stack.Screen
      name="SecureDownload"
      component={SecureDownload}
      options={{
        headerBackTitle: 'Find a Plan',
      }}
    />
  </Stack.Navigator>
);

export default SearchRoutes;
