import React from 'react';
import {StackHeaderProps} from '@react-navigation/stack/lib/typescript/src/types';
import Icon from 'react-native-vector-icons/FontAwesome';
import {TouchableOpacity, Text, ViewStyle} from 'react-native';
import styled from 'styled-components/native';
import {SafeArea} from '../../styled-components/SafeArea';
import {HeaderWrapper} from '../../styled-components/Header';

const ButtonWrapper = styled(TouchableOpacity)`
  flex-direction: row;
  align-items: center;
`;

const ButtonText = styled(Text)`
  color: white;
  font-weight: bold;
  font-size: 16px;
  margin-left: 10px;
`;

const Header = ({scene, previous, navigation}: StackHeaderProps) => {
  const {options} = scene.descriptor;

  return (
    <SafeArea
      background={
        options.headerStyle
          ? (options.headerStyle as ViewStyle).backgroundColor
          : undefined
      }>
      <HeaderWrapper>
        {previous && (
          <ButtonWrapper
            hitSlop={{top: 20, bottom: 20, left: 20, right: 20}}
            onPress={previous ? navigation.goBack : undefined}>
            {options.headerBackImage ? (
              options.headerBackImage({
                tintColor: options.headerTintColor || '',
              })
            ) : (
              <Icon
                name="angle-left"
                size={28}
                color={options.headerTintColor || 'white'}
              />
            )}
            <ButtonText>{options.headerBackTitle}</ButtonText>
          </ButtonWrapper>
        )}
        {options.headerRight &&
          options.headerRight({
            tintColor: options.headerTintColor || '',
          })}
      </HeaderWrapper>
    </SafeArea>
  );
};

export default Header;
