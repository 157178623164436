import React, {PropsWithChildren} from 'react';
import {View} from 'react-native';
import styled from 'styled-components/native';
import Centered from '../../styled-components/Centered';
import Icon from 'react-native-vector-icons/FontAwesome';

const Indicator = styled(View)`
  border-width: 10px;
  border-color: rgb(244, 219, 228);
  border-radius: 150px;
  background: rgb(243, 170, 174);
  padding: 50px;
  margin-bottom: 20px;
`;

const NotFound = ({children}: PropsWithChildren<{}>) => (
  <Centered>
    <Indicator>
      <Icon name="search-minus" size={40} color={'rgb(188, 0, 0)'} />
    </Indicator>
    {children}
  </Centered>
);

export default NotFound;
