import React, { useContext, useEffect, useState, FC } from 'react';
import ContentSignatureContext from '../contexts/ContentSignatureContext';
import { Platform } from 'react-native';
import { lambdaUrl } from '../api';

export const ContentSignatureProvider: FC = ({ children }) => {
  const [contentSignature, setContentSignature] = useState('');

  const getContentSignature = async () => {
    if (Platform.OS !== 'web') {
      return null;
    }

    try {
      const resp = await fetch(`${lambdaUrl}/sign`, { method: 'GET' });
      const data = await resp.json();
      setContentSignature(`?${data?.params}`);
    } catch (e) {
      console.error(e.message);
    }
  };

  useEffect(() => {
    getContentSignature();
  }, []);

  return (
    <ContentSignatureContext.Provider value={{ contentSignature }}>
      {children}
    </ContentSignatureContext.Provider>
  );
};

export const useContentSignature = () => useContext(ContentSignatureContext);
