import React, {Suspense, useEffect, useState} from 'react';
import Screen from '../../styled-components/Screen';
import api, {PlanSearchResult} from '../../api';
import {handlePending, SuspenseResource} from '../../utils/suspense';
import ResultsList from './ResultsList';
import {ScrollView} from '../general/ScrollView';
import CenteredIndicator from '../general/CenteredIndicator';
import {Header, Title} from '../../styled-components/Header';
import Error from '../general/Error';
import ErrorBoundary from '../general/ErrorBoundary';

const Samples = () => {
  const [results, setResults] = useState<
    SuspenseResource<PlanSearchResult[]>
  >();

  useEffect(() => {
    setResults(handlePending(api.getPlansByGroup('sample')));
  }, []);

  return (
    <>
      <Header>
        <Title>Download sample plans</Title>
      </Header>
      <ScrollView>
        <Screen>
          <ErrorBoundary fallback={<Error />}>
            <Suspense fallback={<CenteredIndicator />}>
              {results && (
                <ResultsList returnRoute="Samples" resultsResource={results} />
              )}
            </Suspense>
          </ErrorBoundary>
        </Screen>
      </ScrollView>
    </>
  );
};

export default Samples;
