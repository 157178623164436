import React from 'react';
import {View, Image as ImageComponent} from 'react-native';
import styled from 'styled-components/native';

const ImageWrapper = styled(View)`
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  overflow: hidden;
  width: 50px;
  height: 50px;
  align-self: flex-start;
`;

const Image = styled(ImageComponent)`
  flex-grow: 1;
`;

interface ImageIconProps {
  source: {uri: string};
}

const ImageIcon = ({source}: ImageIconProps) => (
  <ImageWrapper>
    <Image source={source} />
  </ImageWrapper>
);

export default ImageIcon;
