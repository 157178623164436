import React from 'react';
import usePlans from '../hooks/usePlans';
import useNotifications from '../hooks/useNotifications';
import Notification from './general/Notification';
import PlansContext from '../contexts/PlansContext';
import Routes from './Routes';
import {DOA} from '../database/doa';

interface MainProps {
  doaResource: {read: () => DOA};
}

const Main = ({doaResource}: MainProps) => {
  const doa = doaResource.read();
  const plans = usePlans(doa);
  const {
    notifications,
    clearNotification,
    sendNotification,
  } = useNotifications();
  // Native db requests are synchronous
  // Web db is async
  const initialRoute = doa.getCollection('Plan').length ? 'Plans' : 'Search';

  return (
    <PlansContext.Provider value={{doa, plans, sendNotification}}>
      {!!notifications.length &&
        notifications.map((notification) => (
          <Notification
            key={notification.id}
            type={notification.type}
            message={notification.message}
            onTimeout={() => clearNotification(notification)}
          />
        ))}
      <Routes initialRoute={initialRoute} />
    </PlansContext.Provider>
  );
};

export default Main;
