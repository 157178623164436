import React from 'react';
import {View, ActivityIndicator} from 'react-native';
import styled from 'styled-components/native';
import Icon from 'react-native-vector-icons/FontAwesome';
import {DownloadStatus} from '../../hooks/usePlans';

interface IconWrapperProps {
  background: string;
}

const IconWrapper = styled(View)<IconWrapperProps>`
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 25px;
  background: ${(props) => props.background};
`;

interface DownloadStatusProps {
  status: DownloadStatus;
}

const DownloadStatusIndicator = ({status}: DownloadStatusProps) => {
  const [name, background] =
    status === 'ready'
      ? ['download', 'rgb(41, 191, 241)']
      : status === 'error'
      ? ['exclamation', 'rgb(254, 0, 0)']
      : ['check', 'rgb(0, 197, 96)'];

  return (
    <>
      {status === 'pending' ? (
        <ActivityIndicator color="black" />
      ) : (
        <IconWrapper background={background}>
          <Icon name={name} size={16} color="white" />
        </IconWrapper>
      )}
    </>
  );
};

export default DownloadStatusIndicator;
