import {useState} from 'react';
import uuid from 'react-native-uuid';

export type NotificationType = 'alert' | 'info';

export type Notification = {
  id: string;
  type: NotificationType;
  message: string;
};

const useNotifications = () => {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  const sendNotification = (type: NotificationType, message: string) => {
    setNotifications([...notifications, {id: uuid.v1(), type, message}]);
  };

  const clearNotification = (notification: Notification) => {
    setNotifications(notifications.filter((n) => n.id !== notification.id));
  };

  return {notifications, sendNotification, clearNotification};
};

export default useNotifications;
