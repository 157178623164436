import styled from 'styled-components/native';
import {TouchableOpacity} from 'react-native';

const ActionButton = styled(TouchableOpacity)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  padding: 20px 0;
  border-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: rgba(0, 0, 0, 0.2);
`;

export default ActionButton;
