import {ObjectSchema} from 'realm';
import {
  ContentMeta as ContentMetaShape,
  ContentDisplay as ContentDisplayShape,
  Contents as ContentsShape,
  ListContents as ListContentsShape,
  ListContent as ListContentShape,
  ExpandableContents as ExpandableContentsShape,
  ExpandableContent as ExpandableContentShape,
  HtmlContents as HtmlContentsShape,
  FileContents as FileContentsShape,
} from '../../api';
import DOA from '../doa.native';

// Currently, because of an open bug,
// Realm doesn't support class inheritance
// https://github.com/realm/realm-js/issues/2790

// class ContentMeta {
//   title: string;
//   lastUpdate: Date;
// }

const ContentMetaSchema = {
  title: 'string',
  lastUpdate: 'date',
};

// class ContentMetaAndDisplay extends ContentMeta {
//   displayOrder?: number;
//   backgroundColor?: string;
//   textColor?: string;
//   icon?: string;
// }

const ContentDisplaySchema = {
  displayOrder: 'int?',
  backgroundColor: 'string?',
  textColor: 'string?',
  icon: 'string?',
};

const metaAndDisplayToModel = (
  data: ContentMetaShape & ContentDisplayShape,
) => {
  return {
    title: data.Title,
    lastUpdate: new Date(data.LastUpdate),
    displayOrder: data.DisplayOrder,
    backgroundColor: data.BackgroundColor,
    textColor: data.TextColor,
    icon: data.Icon,
  };
};

// class BaseContent extends ContentMeta {
//   contentID: string;
//   isRootContent: boolean;
//   type: string;
// }

const BaseContentSchema = {
  ...ContentMetaSchema,
  contentID: 'string',
  isRootContent: 'bool',
  type: 'string',
};

export class ListContent {
  title: string;
  lastUpdate: Date;
  displayOrder?: number;
  backgroundColor?: string;
  textColor?: string;
  icon?: string;
  // Sub class types
  parentPlanContentID: string;
  nextPlanContentID: string;
  listContentID: string;

  static schema: ObjectSchema = {
    name: 'ListContent',
    primaryKey: 'listContentID',
    properties: {
      ...ContentMetaSchema,
      ...ContentDisplaySchema,
      parentPlanContentID: 'string',
      nextPlanContentID: 'string',
      listContentID: 'string',
    },
  };

  static apiToModel(data: ListContentShape) {
    return {
      ...metaAndDisplayToModel(data),
      parentPlanContentID: data.ParentPlanContentID,
      nextPlanContentID: data.NextPlanContentID,
      listContentID: data.ListContentID,
    };
  }
}

export class ExpandableContent {
  title: string;
  lastUpdate: Date;
  displayOrder?: number;
  backgroundColor?: string;
  textColor?: string;
  icon?: string;
  // Sub class types
  expandableContentID: string;
  expandableContentHTMLFile: string;

  static schema: ObjectSchema = {
    name: 'ExpandableContent',
    primaryKey: 'expandableContentID',
    properties: {
      ...ContentMetaSchema,
      ...ContentDisplaySchema,
      expandableContentID: 'string',
      expandableContentHTMLFile: 'string',
    },
  };

  static apiToModel(data: ExpandableContentShape) {
    return {
      ...metaAndDisplayToModel(data),
      expandableContentID: data.ExpandableContentID,
      expandableContentHTMLFile: data.ExpandableContentHTMLFile,
    };
  }
}

export class Contents {
  title: string;
  lastUpdate: Date;
  contentID: string;
  isRootContent: boolean;
  type: string;
  // Sub class types
  listContents: ListContent[];
  expandableContents: ExpandableContent[];
  indexFile?: string;
  file?: string;

  static schema: ObjectSchema = {
    name: 'Contents',
    primaryKey: 'contentID',
    properties: {
      ...BaseContentSchema,
      listContents: 'ListContent[]',
      expandableContents: 'ExpandableContent[]',
      indexFile: 'string?',
      file: 'string?',
    },
  };

  static baseContentToModel(data: ContentsShape) {
    return {
      title: data.Title,
      lastUpdate: new Date(data.LastUpdate),
      contentID: data.ContentID,
      isRootContent: data.IsRootContent,
      type: data.Type,
    };
  }

  static apiToModel(data: ContentsShape) {
    switch (data.Type) {
      case 'L':
        return {
          ...Contents.baseContentToModel(data),
          listContents: (data as ListContentsShape).ListContents.map(
            ListContent.apiToModel,
          ),
          expandableContents: [],
        };
      case 'E':
        return {
          ...Contents.baseContentToModel(data),
          listContents: [],
          expandableContents: (data as ExpandableContentsShape).ExpandableContents.map(
            ExpandableContent.apiToModel,
          ),
        };
      case 'H':
        return {
          ...Contents.baseContentToModel(data),
          listContents: [],
          expandableContents: [],
          indexFile: (data as HtmlContentsShape).IndexFile,
        };
      case 'F':
      case 'A':
        return {
          ...Contents.baseContentToModel(data),
          listContents: [],
          expandableContents: [],
          file: (data as FileContentsShape).File,
        };
    }
  }

  static headerColors(db: DOA, contentId?: string) {
    const content: ListContent | ExpandableContent | undefined =
      contentId && db.getDocument('ListContent', contentId)
        ? db.getDocument('ListContent', contentId)
        : contentId && db.getDocument('ExpandableContent', contentId)
        ? db.getDocument('ExpandableContent', contentId)
        : undefined;

    return {
      background: content ? content.backgroundColor : undefined,
      tint: content ? content.textColor : undefined,
    };
  }
}
