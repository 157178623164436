import React, {PropsWithChildren, useState} from 'react';
import AsyncStorage from '@react-native-community/async-storage';
import {SafeArea} from '../styled-components/SafeArea';
import {windowIsWebDesktop, maxWidth} from '../styled-components/windowIsWeb';
import {
  Description,
  Header,
  HeaderWrapper,
  Title,
} from '../styled-components/Header';
import {TouchableOpacity, Text, Platform} from 'react-native';
import WebView from './general/WebView';
import styled from 'styled-components/native';
import LinearGradient from 'react-native-linear-gradient';
import label from '../label';

const ButtonWrapper = styled(LinearGradient)`
  position: fixed;
  bottom: 0;
  height: 15%;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
`;

const Button = styled(TouchableOpacity)`
  flex-direction: row;
  justify-content: center;
  padding: 20px 40px;
  min-width: 200px;
  margin-top: 20px;
  ${windowIsWebDesktop &&
  `
    margin: 20px auto;
    width: ${maxWidth.element}
  `};
  background: ${(props) => props.theme.primary};
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  elevation: 3;
  border-radius: 5px;
`;

const ButtonText = styled(Text)`
  color: white;
  font-weight: bold;
`;

interface TermsConditionsProps {
  termsResource: {read: () => string};
}

const TermsConditions = ({
  termsResource,
  children,
}: PropsWithChildren<TermsConditionsProps>) => {
  const terms = termsResource.read();
  // const [eulaLoaded, setEulaLoaded] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const {eulaTitle, eulaInstructions} = label;

  const handleAcceptTerms = async () => {
    await AsyncStorage.setItem('terms-accepted', 'true');
    setTermsAccepted(true);
  };

  if (terms === 'true' || termsAccepted) {
    return <>{children}</>;
  } else {
    return (
      <>
        <SafeArea>
          <HeaderWrapper />
        </SafeArea>
        <Header>
          <Title>{eulaTitle}</Title>
          <Description>{eulaInstructions}</Description>
        </Header>
        <WebView
          source={
            Platform.OS === 'android'
              ? {
                  uri: 'file:///android_asset/eula.html',
                }
              : require('../assets/eula.html')
          }
        />
        <ButtonWrapper
          colors={['rgba(255, 255, 255, 0)', 'rgba(255, 255, 255, 1)']}>
          <Button onPress={handleAcceptTerms}>
            <ButtonText>ACCEPT</ButtonText>
          </Button>
        </ButtonWrapper>
      </>
    );
  }
};

export default TermsConditions;
