import styled from 'styled-components/native';
import {Text} from 'react-native';
import {Grow} from './Row';

export const Field = styled(Grow)`
  flex-grow: 1;
`;

export const Label = styled(Text)`
  color: #666666;
  margin-bottom: 10px;
`;

export const Value = styled(Text)`
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
`;

export const ValueStack = styled(Value)`
  margin-bottom: 10px;
`;
