import React, {useEffect, useRef, useState} from 'react';
import {TouchableOpacity} from 'react-native';
import {useIsFocused} from '@react-navigation/native';
import styled from 'styled-components/native';
import {Track} from 'react-native-track-player';
import Icon from 'react-native-vector-icons/FontAwesome';
import Centered from '../../styled-components/Centered';
import Screen from '../../styled-components/Screen';

import audioFile from './test-audio.mp3';

const PlayButton = styled(TouchableOpacity)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
  margin-bottom: 16px;
  background: white;
  border-radius: 100px;
  border-width: 15px;
  border-color: rgb(196, 206, 240);
`;

interface AudioProps {
  source: Track;
}

const Audio = ({source}: AudioProps) => {
  // let audioRef = useRef<HTMLAudioElement | undefined>();
  const pageFocus = useIsFocused();
  let audioRef: HTMLAudioElement | null;
  const [playing, setPlaying] = useState(false);

  useEffect(() => resetAudio(), [pageFocus]);
  
  const resetAudio = () => {
    setPlaying(false);
    (audioRef as HTMLAudioElement).pause();
    (audioRef as HTMLAudioElement).currentTime = 0;
  };

  const handlePlayPressed = () => {
    switch (playing) {
      case true:
        setPlaying(false);
        (audioRef as HTMLAudioElement).pause();
        break;
      case false:
        setPlaying(true);
        (audioRef as HTMLAudioElement).play();
        break;
    }
  };

  return (
    <Screen>
      <Centered>
        <PlayButton onPress={handlePlayPressed}>
          <Icon
            name={playing ? 'pause' : 'play'}
            size={40}
            color={'rgb(95, 105, 142)'}
          />
        </PlayButton>
        <audio controls
          ref={input => audioRef = input}
          src={audioFile}
          // src={source}
        ></audio>
      </Centered>
    </Screen>
  );
};

export default Audio;
