import styled from 'styled-components/native';
import {Text, TouchableOpacity, View} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';
import {windowIsWebDesktop, maxWidth} from './windowIsWeb';

export const isWhite = (value?: string) =>
  value && (value === 'white' || value === '#fff' || value === '#ffffff');

export const ListContentItem = styled(TouchableOpacity)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  padding: 15px 20px;
  margin: 10px auto;
  border-radius: 5px;
  overflow: hidden;
  ${windowIsWebDesktop && `
    width: ${maxWidth.container};
  `}
`;

type BackgroundProps = {
  background: string;
};

export const Background = styled(View)<BackgroundProps>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: ${(props) => (isWhite(props.background) ? '1' : '0.15')};
  background: ${(props) => props.background};
`;

export const TextWrapper = styled(Text)`
  max-width: 90%;
  position: relative;
  font-size: 16px;
  font-weight: bold;
`;

export const IconWrapper = styled(Icon)<BackgroundProps>`
  border-radius: 5px;
  background: ${(props) => props.background};
  border-width: ${(props) => (isWhite(props.background) ? '1' : '0')}px;
  border-color: rgba(0, 0, 0, 0.2);
  padding: 5px;
  overflow: hidden;
`;
