import React from 'react';
import {SafeAreaView, View} from 'react-native';
import styled from 'styled-components/native';
import ResponsiveImage from '../../styled-components/ResponsiveImage';

const SafeArea = styled(SafeAreaView)`
  padding-top: 0;
  background: ${(props) => props.theme.primary};
`;

const IconHeaderWrapper = styled(View)`
  align-items: center;
  justify-content: flex-end;
  background: ${(props) => props.theme.primary};
`;

const IconWrapper = styled(View)`
  transform: translateY(30px);
  width: 90px;
  height: 90px;
  border-radius: 10px;
  border: 5px solid white;
`;

const IconImage = styled(ResponsiveImage)`
  border-radius: 5px;
`;

interface IconHeaderProps {
  source: any;
}

const IconHeader = ({source}: IconHeaderProps) => (
  <SafeArea>
    <IconHeaderWrapper>
      <IconWrapper>
        <IconImage source={source} resizeMode="contain" />
      </IconWrapper>
    </IconHeaderWrapper>
  </SafeArea>
);

export default IconHeader;
