import React from 'react';
import {Platform} from 'react-native';
import {NavigationContainer, DefaultTheme} from '@react-navigation/native';
import {createBottomTabNavigator} from '@react-navigation/bottom-tabs';
import { createURL } from 'expo-linking';

import FoundationIcon from 'react-native-vector-icons/Foundation';
import Icon from 'react-native-vector-icons/MaterialIcons';
import IonIcon from 'react-native-vector-icons/Ionicons';

import MainTabBar from './general/MainTabBar';
import SearchRoutes, {
  SearchStackParamList,
} from './search-download/SearchRoutes';
import Flashlight from './Flashlight';
import About from './About';
import PlanRoutes, {PlanStackParamList} from './plans/PlanRoutes';
import ContactRoutes, {ContactsStackParamList} from './contacts/ContactRoutes';
import Redirect from './general/Redirect';

// https://github.com/react-navigation/react-navigation/issues/6931
export type NestedNavigatorParams<ParamList> = {
  [K in keyof ParamList]: undefined extends ParamList[K]
    ? {screen: K; params?: ParamList[K]}
    : {screen: K; params: ParamList[K]};
}[keyof ParamList];

export type RootTabsParamList = {
  Plans: NestedNavigatorParams<PlanStackParamList>;
  Search: NestedNavigatorParams<SearchStackParamList>;
  Contacts: NestedNavigatorParams<ContactsStackParamList>;
  Flashlight: undefined;
  About: undefined;
  SamplesRedirect: {
    state: {routes: any[]};
  };
};

const Tab = createBottomTabNavigator<RootTabsParamList>();

interface RoutesProps {
  initialRoute:
    | 'Plans'
    | 'Search'
    | 'Contacts'
    | 'Flashlight'
    | 'About'
    | 'SamplesRedirect';
};

const prefix = createURL('/');
const Routes = ({initialRoute}: RoutesProps) => {
  const navigationTheme = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      background: 'rgb(241, 243, 254)',
    },
  };

  const linking = {
    prefixes: [prefix],
    config: {
      screens: {
        Plans: {
          initialRouteName: 'PlansList',
          path: 'plans',
          screens: {
            PlansList: '',
            Content: ':planId',
          },
        },
        Contacts: {
          initialRouteName: 'PlanList',
          path: 'contacts',
          screens: {
            PlanList: 'plans',
            ContactList: 'plan/:planId/contacts',
            Contact: 'plan/:planId/contact/:contactId',
          },
        },
        Search: {
          initialRouteName: 'SearchList',
          path: 'search',
          screens: {
            SearchHome: '',
            SearchList: 'results',
            Samples: 'samples',
            SecureDownload: 'download',
          },
        },
        About: 'about',
      }
    },
  };

  return (
    <NavigationContainer linking={linking} theme={navigationTheme}>
      <Tab.Navigator
        initialRouteName={initialRoute}
        tabBar={(props) => <MainTabBar {...props} />}
      >
        <Tab.Screen
          name="Plans"
          component={PlanRoutes}
          options={{
            tabBarIcon: ({color, size}) => (
              <FoundationIcon name="home" size={size} color={color} />
            ),
          }}
        />
        <Tab.Screen
          name="Contacts"
          component={ContactRoutes}
          options={{
            tabBarIcon: ({color, size}) => (
              <Icon name="dialpad" size={size} color={color} />
            ),
          }}
        />
        <Tab.Screen
          name="Search"
          component={SearchRoutes}
          options={{
            tabBarIcon: ({color, size}) => (
              <Icon name="search" size={size} color={color} />
            ),
          }}
        />
        {Platform.OS !== 'web' && (
          <Tab.Screen
            name="Flashlight"
            component={Flashlight}
            options={{
              tabBarIcon: ({color, size}) => (
                <IonIcon name="flashlight" size={size} color={color} />
              ),
            }}
          />
        )}
        <Tab.Screen
          name="About"
          component={About}
          options={{
            tabBarIcon: ({color, size}) => (
              <IonIcon name="information-circle" size={size} color={color} />
            ),
          }}
        />
        <Tab.Screen
          name="SamplesRedirect"
          component={Redirect}
          options={{
            title: 'Download sample plan',
            tabBarIcon: ({color, size}) => (
              <IonIcon name="download" size={size} color={color} />
            ),
          }}
          listeners={({navigation}) => ({
            tabPress: (e) => {
              e.preventDefault();
              navigation.reset({
                routes: [
                  {
                    name: 'Search',
                    state: {
                      routes: [{name: 'SearchHome'}, {name: 'Samples'}],
                    },
                  },
                ],
              });
            },
          })}
        />
      </Tab.Navigator>
    </NavigationContainer>
  );
};

export default Routes;
