import {Dimensions, Platform} from 'react-native';

export const breakpoints = {
  phone: 768,
  tablet: 940,
};

export const maxWidth = {
  container: '600px',
  element: '420px'
};

export const windowIsWeb = Platform.OS === 'web';

export const windowIsMobile = Dimensions.get('window').width < breakpoints.phone;

export const windowIsTablet = 
  Dimensions.get('window').width < breakpoints.tablet &&
  Dimensions.get('window').width >= breakpoints.phone;

export const windowIsWebDesktop = !windowIsMobile && windowIsWeb;
export const windowIsWebTablet = windowIsTablet && windowIsWeb;

export default windowIsWebDesktop;
