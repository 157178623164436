import React, {useContext} from 'react';
import {ScrollView} from '../general/ScrollView';
import Screen from '../../styled-components/Screen';
import {Plan} from '../../database/schemas/plan';
import PlanListItem from './PlanListItem';
import {useNavigation} from '@react-navigation/core';
import {NativeStackNavigationProp} from 'react-native-screens/lib/typescript/types';
import {PlanStackParamList} from './PlanRoutes';
import {ListItem} from '../../styled-components/List';
import {Header, Title} from '../../styled-components/Header';
import CenteredIndicator from '../general/CenteredIndicator';
import PlansContext from '../../contexts/PlansContext';
import {Download} from '../../hooks/usePlans';
import {Platform} from 'react-native';

const PlanList = () => {
  const navigation = useNavigation<
    NativeStackNavigationProp<PlanStackParamList>
  >();
  const context = useContext(PlansContext);
  const filter =
    Platform.OS === 'web'
      ? (p: Plan) =>
          !context!.plans.downloads
            .map((p: Download) => p.planId)
            .includes(p.planID)
      : context!.plans.downloads
          .map((d: Download) => `planID != "${d.planId}"`)
          .join(' AND ');
  const sort =
    Platform.OS === 'web'
      ? (p1: Plan, p2: Plan) =>
          p1.name < p2.name ? -1 : p1.name > p2.name ? 1 : 0
      : 'name';
  const plans = context!.doa.getCollection<Plan>('Plan', filter, sort);

  return (
    <>
      <Header>
        <Title>My Plans</Title>
      </Header>
      <ScrollView>
        <Screen>
          {context!.plans.deleting ? (
            <CenteredIndicator />
          ) : (
            <>
              {plans.map((plan: Plan) => {
                return (
                  <ListItem
                    key={plan.planID}
                    onPress={() =>
                      navigation.push('Content', {
                        planId: plan.planID,
                      })
                    }>
                    <PlanListItem plan={plan} />
                  </ListItem>
                );
              })}
            </>
          )}
        </Screen>
      </ScrollView>
    </>
  );
};

export default PlanList;
