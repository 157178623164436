import React, {Suspense} from 'react';
import TermsConditions from './components/TermsConditions';
import AsyncStorage from '@react-native-community/async-storage';
import Main from './components/Main';
import CenteredIndicator from './components/general/CenteredIndicator';
import {handlePending} from './utils/suspense';
import {StatusBar} from 'react-native';
import {ThemeProvider} from 'styled-components/native';
import startDatabase from './database/start';
import Config from 'react-native-config';
import label from './label/index';
import { ContentSignatureProvider } from './components/ContentSignature';

const terms = handlePending(AsyncStorage.getItem('terms-accepted'));
const doa = handlePending(startDatabase());

const html = window.document.querySelector('html');
if (html) {
  const styleSheet = window.document.createElement('style');
  styleSheet.innerText = '#root {max-height: 100vh;}';
  html.appendChild(styleSheet);
}

const App = () => {
  return (
    <ContentSignatureProvider>
      <ThemeProvider theme={label.theme}>
        <StatusBar translucent={true} backgroundColor={label.theme.statusBar} />
        <Suspense fallback={<CenteredIndicator />}>
          <TermsConditions termsResource={terms}>
            <Main doaResource={doa} />
          </TermsConditions>
        </Suspense>
      </ThemeProvider>
    </ContentSignatureProvider>
  );
};

export default App;
