import {useEffect, useState} from 'react';
import {SuspenseResource} from '../utils/suspense';

export const useSuspense = (
  resource: SuspenseResource<any> | undefined,
): [boolean, Error | undefined] => {
  const [pending, setPending] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    try {
      if (resource) {
        resource.read();
      }
    } catch (suspenderOrError) {
      if (suspenderOrError.status) {
        // resource was read as error
        setPending(false);
        setError(suspenderOrError);
      } else {
        // resource was read as promise
        setPending(true);
        suspenderOrError.then(() => setPending(false));
      }
    }
  }, [resource]);

  return [pending, error];
};
