import React from 'react';
import Screen from '../../styled-components/Screen';
import ScrollView from '../general/ScrollView';
import {StackNavigationProp} from '@react-navigation/stack';
import {CompositeNavigationProp} from '@react-navigation/native';
import {TouchableWithoutFeedback, Text, View} from 'react-native';
import InputBox, {TextArea} from '../general/InputBox';
import styled from 'styled-components/native';
import Divider from '../general/Divider';
import Icon from 'react-native-vector-icons/FontAwesome';
import IconNavButton from '../general/IconNavButton';
import {SearchStackParamList} from './SearchRoutes';
import {BottomTabNavigationProp} from '@react-navigation/bottom-tabs';
import {RootTabsParamList} from '../Routes';
import IconHeader from '../general/IconHeader';

const Title = styled(Text)`
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
`;

const Description = styled(Text)`
  max-width: 280px;
  margin: 15px auto;
  color: rgba(0, 0, 0, 0.7);
  text-align: center;
  line-height: 22px;
`;

const DownloadIcon = styled(View)`
  padding: 13px;
  background: ${(props) => props.theme.primary};
  border-radius: 5px;
`;

type SearchHomeNavigationProp = CompositeNavigationProp<
  StackNavigationProp<SearchStackParamList, 'SearchHome'>,
  BottomTabNavigationProp<RootTabsParamList>
>;

interface SearchHomeProps {
  navigation: SearchHomeNavigationProp;
}

const SearchHome = ({navigation}: SearchHomeProps) => (
  <>
    <IconHeader source={require('../../assets/logo.png')} />
    <ScrollView>
      <Screen>
        <Title>Find a Plan</Title>
        <Description>
          To get started, enter your Organization Code / Search Term
        </Description>
        <InputBox
          background={'rgb(223, 230, 252)'}
          color={'rgb(97, 108, 146)'}
          icon="search"
          webSize="element">
          <TouchableWithoutFeedback onPress={() => navigation.push('SearchList')}>
            <TextArea color={'rgba(97, 108, 146, 0.4)'}>
              Enter organization code
            </TextArea>
          </TouchableWithoutFeedback>
        </InputBox>
        <Divider
          text="OR"
          color={'rgb(97, 108, 146)'}
          lineColor={'rgba(97, 108, 146, 0.4)'}
          background={'rgb(241, 243, 254)'}
        />
        <IconNavButton
          text="Download sample plan"
          icon={
            <DownloadIcon>
              <Icon name="download" size={24} color="white" />
            </DownloadIcon>
          }
          onPress={() => navigation.navigate('Samples' as any)}
        />
      </Screen>
    </ScrollView>
  </>
);

export default SearchHome;
